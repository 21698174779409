<template>
  <div class="mb-10">
    <v-card color="blue-grey lighten-5" class="rounded-0 pa-5">
      <div class="d-flex align-center pb-5 sr-filter-row">
        <select-user-group v-model="userGroup"></select-user-group>
        <v-select
          v-model="user"
          :items="filtredUsers"
          item-value="id"
          item-text="name"
          dense
          hide-details
          clearable
          label="Пользователь"
          color="teal"
          class="flex-grow-0 ml-5"
          item-color="teal lighten-1">
        </v-select>
        <!-- <v-col sm="2">
          <v-select
            v-if="tags.length"
            v-model="tag"
            :items="tags"
            item-value="id"
            item-text="name"
            dense
            hide-details
            clearable
            label="Тег"
            color="teal"
            item-color="teal lighten-1">
          </v-select>
        </v-col> -->
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="dates"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Временной интервал"
              readonly
              dense
              hide-details
              clearable
              color="teal"
              v-bind="attrs"
              v-on="on"
              class="flex-grow-0 ml-5"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            scrollable
            first-day-of-week="1"
            range
            color="teal"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="blue-grey"
              @click="modal = false"
            >
              Отмена
            </v-btn>
            <v-btn
              text
              color="teal"
              @click="$refs.dialog.save(dates)"
            >
              Сохранить
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-spacer></v-spacer>
        
      </div>
      <div class="d-flex align-center">
        <v-select
          v-model="syllabiIds"
          :items="syllabi"
          item-value="id"
          item-text="name"
          dense
          hide-details
          attach
          chips
          multiple
          label="Программа"
          color="teal"
          class="flex-grow-1"
          item-color="teal lighten-1">
        </v-select>
        <v-spacer></v-spacer>
      </div>
    </v-card>
    <v-card min-height="470" tile class="mb-10 overflow-x-auto">
      <div class="w-100 mw-550">
        <apexchart height="450" type="line" :options="options" :series="series"></apexchart>
      </div>
    </v-card>
    <v-btn class="mb-2 mr-2" text color="blue-grey" @click="all">
      Развернуть все
      <v-icon color="teal" >
        mdi-menu-down
      </v-icon>
    </v-btn>
    <v-btn class="mb-2" text color="blue-grey" @click="none">
      Свернуть
      <v-icon color="teal" >
        mdi-menu-up
      </v-icon>
    </v-btn>
    <v-expansion-panels tile v-model="openItem" multiple>
      <v-expansion-panel readonly>
        <v-expansion-panel-header class="px-sm-2 px-0 py-0 text-caption sr-ex-panel-header" color="blue-grey lighten-5">
          <template v-slot:actions class="order-1">
            <div class="trainings__icon"></div>
          </template>
          <v-row align="center" class="my-0 order-2">
            <v-col cols="12" sm="3" class="py-sm-2 pt-2 pb-0">
              <div class="ml-sm-2">Пользователь</div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" lg="auto" sm="3" class="py-sm-2 py-0 ">
              Название
            </v-col>
            <v-spacer/>
            <v-divider vertical></v-divider> 
            <v-col cols="4" lg="1" sm="2" class="py-sm-2 pb-2 pt-0">
              Дата создания
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="4" lg="1" sm="2" class="py-sm-2 pb-2 pt-0">
              Дата последней тренировки
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="auto" sm="auto" class="py-sm-2 pb-2 pt-0">
              Прогресс
            </v-col>
          </v-row>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel 
        v-for="(syllabus) in syllabi"
        :key="syllabus.id"
        class="sr-item"
        active-class="grey lighten-5">
        <v-expansion-panel-header class="px-sm-2 px-0 py-0">
          <template v-slot:actions class="order-1">
            <v-icon color="teal" class="">
              mdi-menu-down
            </v-icon>
          </template>
          <v-row align="center" class="my-0 order-2">
            <v-col cols="12" sm="3">
              <div class="ml-sm-2">{{ syllabus.client.name }}</div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" lg="auto" sm="3">
              {{ syllabus.name }}
            </v-col>
            <v-spacer/>
            <v-divider vertical></v-divider> 
            <v-col cols="4" lg="1" sm="2">
              <div v-if="syllabus.created">
                {{ new Date(syllabus.created) | moment("DD.MM.YYYY HH:mm") }}
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="4" lg="1" sm="2">
              <div v-if="syllabus.tasks.last_training">
              {{ new Date(syllabus.tasks.last_training) | moment("DD.MM.YYYY HH:mm") }}
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="2" sm="auto">
              <v-progress-circular
                :rotate="-90"
                :size="40"
                :width="5"
                :value="syllabus.tasks.completed_percent"
                color="teal"
                class="mx-2"
              >
              {{ syllabus.tasks.completed_percent }}
              </v-progress-circular>
            </v-col>
          </v-row>        
        </v-expansion-panel-header>
        <v-expansion-panel-content class="sr-ex-panel-content">
          <syllabus-show :syllabus="syllabus"></syllabus-show>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import SelectUserGroup from '../main/SelectUserGroup'
import SyllabusShow from '../syllabi/SyllabusShow'
export default {
  components: {
    SelectUserGroup,
    SyllabusShow
  },
  data: () => ({
    dates: [],
    modal: false,
    userGroup: null,
    syllabi: [],
    syllabiIds: [],
    openItem: [],
    user: null,
    users: [],
    tag: null,
    trainingGroups: [],
    trainingGroup: [],
    training: null,
  }),
  created() {
    this.getUsers();
    this.getSyllabiResults();
    this.getTrainingGroups();
  },
  watch: {
    userGroup() {
      let user = this.filtredUsers.find(u => u.id == this.user);
      if (!user) {
        this.user = null;
      }
    },
    trainingGroup() {
      let training = this.filtredTrainings.find(t => t.id == this.training);
      if (!training) {
        this.training = null;
      }
    },
  },
  computed: {
      dateRangeText: {
        get() {
          return this.dates.join(' ~ ')
        },
        set() {
          this.dates = [];
        }        
      },
      tags() {
        return this.$store.state.tags;
      },
      categories() {
        let categories = this.filtredSyllabi.map(s => s.name.split(' '));

        return categories;
      },
      filtredSyllabi() {
        let syllabi = this.syllabi;
        if (this.user) {
          syllabi = syllabi.filter(s => s.client.id == this.user)
        } else if (this.userGroup) {
          let uids = this.filtredUsers.map(u => u.id);
          syllabi = syllabi.filter(s => uids.includes(s.client.id))
        }
        if (this.dates.length) {
          syllabi = syllabi.filter(s => this.$moment(s.created).isBetween(this.dates[0], this.dates[1]))
        }
         syllabi = syllabi.filter(s => this.syllabiIds.includes(s.id))
        return syllabi;
      },
      plans() {
        let plans = this.filtredSyllabi.map(s => s.plan.avg_plan);
        return plans;
      },
      facts() {
        let facts = this.filtredSyllabi.map(s => s.fact.avg_fact);
        return facts;
      },
      options() {
        let options = {
          chart: {
            type: 'line',
            zoom: {
              enabled: false
            },
            height: '450px',
            stacked: false
          },
          toolbar: {
            show: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [0, 0],
          },
          xaxis: {
            categories: this.categories,
          },
          yaxis: [
            {
              seriesName: 'Уровень',
              labels: {
                formatter: function(val) {
                  return val.toFixed(0);
                }
              },
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
              },
              title: {
                text: "Уровень сложности",
              },
              tooltip: {
                enabled: true
              }
            }
          ],
        }
        return options;
      },     
      series() {
        let data = 
        [
          {
            name: 'Уровень План',
            type: 'column',
            data: this.plans
          }, {
            name: 'Уровень Факт',
            type: 'column',
            data: this.facts
          }
        ];
        return data
      },
      userGroupIds() {
        let ids = [];
        if (this.userGroup) {
          ids = this.$store.state.userGroups
            .filter(g => g.id == this.userGroup || g.parent_id == this.userGroup)
            .map(g => g.id)
        }
        return ids;
      },
      trainings() {
        return this.$store.state.trainings;
      },
      filtredTrainings() {
        let trainings = this.trainings;
        if (this.trainingGroup) {
          trainings = trainings.filter(g => this.trainingGroup == g.group.id)
        }
        return trainings;
      },
      filtredUsers() {
        let users = this.users.filter(u => u.mode != 'training');
        if (this.userGroup) {
          users = users.filter(u => this.userGroupIds.includes(u.group.id));
        }
        return users;
      },
      
  },
  methods: {
    getUsers() {
      let url = '/users';
       this.$api.get(url)
        .then(resp => {          
          let users = resp.data._embedded.users
            .filter(u => u.role.id == 1)
            .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          this.users = users;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    getTrainingGroups() {
      this.loading = true
      let url = '/trainings-groups';
       this.$api.get(url)
        .then(resp => {
          this.loading = false;
          this.trainingGroups = resp.data._embedded.groups;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    getSyllabiResults() {
      let url = '/results/syllabi';
      this.$api.get(url,{
          params: {
            user_groups: this.groupIds,
            user_id: this.user,
          }
        })
        .then(resp => {
          this.syllabi = resp.data._embedded.syllabi;
          this.syllabiIds = resp.data._embedded.syllabi.map(s => s.id);
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    /* clearFilter() {
      this.user = null;
      this.userGroup = null;
      this.dates = [];
      this.training = null;
      this.trainingGroup = null;
    }, */
    all () {
      let keys = [...this.filtredSyllabi.keys()].map(k => k+1);
      console.log(keys);
      console.log(...Array(this.filtredSyllabi).keys());
      this.openItem = keys
    },
    none () {
      this.openItem = []
    },
  }
}
</script>

<style>

</style>
