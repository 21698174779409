<template>
  <div class="mb-10">
    <v-card color="blue-grey lighten-5" class="rounded-0 pa-5 mb-2">
      
      <v-row>
        <v-col cols="12" lg="4" md="6">
          <v-select
            v-model="syllabus"
            :items="syllabi"
            item-value="id"
            item-text="name"
            dense
            hide-details
            label="Программа"
            color="teal"
            class="flex-grow-0 ml-md-5"
            item-color="teal lighten-1">
          </v-select>
        </v-col>
      </v-row>
    </v-card>
    <v-card min-height="470" tile class="mb-10">
      <div class="w-100">
        <apexchart height="450" :options="options" :series="series"></apexchart>
      </div>
    </v-card>
  </div>    
</template>

<script>

export default {

  data: () => ({
    plan: [],
    fact: [],
    syllabus: null,
    syllabi: [],
  }),
  created() {
    this.getSyllabi();
  },
  computed: {
    categories() {
      let count = Math.max(this.plan.length, this.fact.length);
      let categories = []
      let label = 'День ';
      for (let index = 1; index <= count; index++) {
        categories.push(label+index);          
      }
      return categories;
    },    
    options() {
        let options = {
          chart: {
            type: 'line',
            zoom: {
              enabled: false
            },
            height: '450px',
            stacked: false
          },
          toolbar: {
            show: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [2, 2],
            curve: ['smooth','smooth'],
          },
          fill: {
            opacity: [0.35, 0.35],
          },
          xaxis: {
            categories: this.categories,
          },
          yaxis: [
            {
              seriesName: 'Уровень',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
              },
              title: {
                text: "Уровень сложности",
              },
              tooltip: {
                enabled: true
              },
              labels: {
                formatter: function(val) {
                  return val ? val.toFixed(0) : null;
                }
              },
            }
          ],
        }
        return options;
    },   
    series() {
      let data = 
        [
          {
            name: 'Уровень факт',
            type: 'area',
            data: this.fact
          }, 
          {
            name: 'Уровень план',
            type: 'area',
            data: this.plan
          }
        ];
        return data
    },
  },
  watch: {
    syllabus() {
      if (this.syllabus) {
        this.getResults();
      } else {
        this.plan = [];
        this.fact = [];
      }
      
    }
  },
  methods: {
    getSyllabi() {
      let url = '/syllabi';
      this.$api.get(url)
        .then(resp => {
          console.log(resp.data._embedded);
          this.syllabi = resp.data._embedded.syllabi;
          let syllabus = this.syllabi[0] || null;
          if (syllabus) {
            this.syllabus = syllabus.id;
          }
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    getResults() {
      let url = '/results/user/plan-fact';
      let params = { 
        syllabus_id: this.syllabus
      };
      this.$api.get(url, {params})
        .then(resp => {
          this.plan = resp.data._embedded.plan;
          this.fact = resp.data._embedded.fact;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
  }
}
</script>
