<template>
  <div class="information">
    <page-title :title="'Анализ данных'" ></page-title>
    
      <v-tabs
        v-model="activeTab"
        class="custom__tabs"
        hide-slider
        show-arrows
        background-color="transparent">
        <template v-if="isExpert">
          <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
            <v-icon left>mdi-chart-areaspline</v-icon>
            Общий отчет
          </v-tab>
          <v-tab  active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
            <v-icon left>mdi-head-snowflake</v-icon>
            Программы
          </v-tab>
        </template>
        <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
          План/Факт
        </v-tab>
        <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
          Параметры
        </v-tab>
        <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
          Тренинги
        </v-tab>
      </v-tabs>
      <v-card class="pa-3 rounded-0 flat custom__card">
        <v-tabs-items touchless v-model="activeTab">
          <template v-if="isExpert">
            <v-tab-item class="pt-2">
              <general-report></general-report>
            </v-tab-item> 
            <v-tab-item class="pt-2">
              <syllabi-report></syllabi-report>
            </v-tab-item>   
          </template>
          <v-tab-item class="pt-2">
            <user-plan-fact-report></user-plan-fact-report>
          </v-tab-item>
          <v-tab-item class="pt-2">
            <user-parameters-report></user-parameters-report>
          </v-tab-item>  
          <v-tab-item class="pt-2">
            <user-trainings-report></user-trainings-report>
          </v-tab-item>    
        </v-tabs-items>        
      </v-card>
  </div>
</template>
<script>
import PageTitle from '../components/main/PageTitle'
import GeneralReport from '../components/analitycs/GeneralReport'
import SyllabiReport from '../components/analitycs/SyllabiReport'
import UserTrainingsReport from '../components/analitycs/user/TrainingsReport'
import UserParametersReport from '../components/analitycs/user/ParametersReport'
import UserPlanFactReport from '../components/analitycs/user/PlanFactReport'
export default {
  components: {
    PageTitle,
    GeneralReport,
    SyllabiReport,
    UserTrainingsReport,
    UserParametersReport,
    UserPlanFactReport,
  },
  data: () => ({
    activeTab: 0,
    group: '',
    group2: '',
    groupID: [
      {
        id: 1,
        parent: 0,
        name: 'Группа 1',
      },
      {
        id: 2,
        parent: 1,
        name: 'Подгруппа 1',
      },
      {
        id: 3,
        parent: 1,
        name: 'Подгруппа 2',
      },
    ],
    training: ''
  }),
  computed: {
    trainings() {
      return this.$store.state.trainings;
    },
    isExpert() {
      return this.$store.getters.isExpert;
    }
  },
  methods: {
  }
}
</script> 
